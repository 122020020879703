import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Link } from 'gatsby-plugin-react-intl'
import { useIntl } from 'gatsby-plugin-react-intl'

//import images
import { StaticImage } from 'gatsby-plugin-image'

//import Patientform
import Patientform from '../components/Appointment/patientform'

//styles
import { Container, Col, Row, Button } from 'react-bootstrap'
import * as styles from '../styles/pages/request-appointment.module.scss'

//import icons
import { FaFacebookMessenger } from 'react-icons/fa'

const RequestAppointment = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        title='Book An Appointment'
        description='Learn more about our providers at Kids &amp; Teens Medical Group.  Our team of experts provides a variety of pediatric services to the communities of Los Angeles and surrounding neighborhoods. To book an appointment, call our offices at 818-244-9595'
      />
      <Container>
        <h1
          className={`pt-5 justify-content-center text-center text-uppercase fw-bold `}
        >
          <strong>{intl.formatMessage({ id: 'BookAppointmentH1' })}</strong>
        </h1>
        <p className='justify-content-center text-center text-uppercase fw-bold fs-2'>
          {intl.formatMessage({ id: 'BookAppointmentP' })}
        </p>
        <Row className='justify-content-center'>
          <Col
            lg={5}
            className='mt-5 pt-5 d-flex justify-content-center text-center uppercase mb-10 mb-md-0'
          >
            <div className={` ${styles.boxOnline}`}>
              <div className={` ${styles.boxnumber}`}>
                <StaticImage
                  src='../images/booknow/webapp.png'
                  alt='online'
                  width='60'
                  style={{ top: '15px' }}
                />
              </div>
              <h2
                className={`mt-5 mb-3 pt-5 text-uppercase fw-bold ${styles.boxTitle}`}
              >
                {intl.formatMessage({ id: 'BookOnlineH1' })}
              </h2>
              <h3 className='mb-4'>
                {intl.formatMessage({ id: 'BookOnlineP1' })}
              </h3>

              <div className='border-bottom border-secondary pb-2 mb-4'>
                <Link to='/locations'>
                  <button className={styles.boxOnlinBtn}>
                    <strong>
                      {intl.formatMessage({ id: 'BookOnlineB1' })}
                    </strong>
                  </button>
                </Link>
                <p className='pt-2'>
                  {intl.formatMessage({ id: 'BookOnlineP2' })}
                </p>
              </div>
              <div className='border-bottom border-secondary pb-2 mb-4'>
                <a href='#form'>
                  <button className={styles.boxOnlinBtn}>
                    {intl.formatMessage({ id: 'BookOnlineB2' })}
                  </button>
                </a>
                <p className='pt-2'>
                  {intl.formatMessage({ id: 'BookOnlineP3' })}
                </p>
              </div>
              {/* Telemedicine */}
              <div className='mb-3'>
                <h2 className='text-uppercase fw-bold pb-2'>
                  {intl.formatMessage({ id: 'BookOnlineH2' })}
                </h2>

                <div className='d-flex gap-2 justify-content-between'>
                  <a href='sms:8186582701'>
                    <button
                      className={`fs-5 mb-0 ${styles.boxOnlinBtnTeleMedi}`}
                    >
                      <div className='fs-1'>EN</div>
                      <div className='fs-5'>ENGLISH</div>
                    </button>
                  </a>
                  <a href='sms:8184235637'>
                    <button
                      className={`fs-5 mb-0 ${styles.boxOnlinBtnTeleMedi}`}
                    >
                      <div className='fs-1'>ES</div>
                      <div className='fs-5'>SPANISH</div>
                    </button>
                  </a>
                  <a href='https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F141266270391%2F%3Fmessaging_source%3Dsource%253Apages%253Amessage_shortlink'>
                    <button className={`fs-5 ${styles.boxOnlinBtnTeleMedi}`}>
                      <StaticImage
                        src='../images/booknow/messenger-icon.png'
                        alt='messenger'
                      />
                      <div className='fs-5 mt-2'>MESSENGER</div>
                    </button>
                  </a>
                </div>
                <p className='pt-2'>
                  {intl.formatMessage({ id: 'BookOnlineP4' })}
                </p>
              </div>
            </div>
          </Col>

          <Col
            lg={5}
            className='mt-5 pt-5 d-flex justify-content-center text-center uppercase'
          >
            <div className={` ${styles.boxOperator}`}>
              <div className={` ${styles.boxnumber}`}>
                <StaticImage
                  src='../images/booknow/agent.png'
                  alt='operator'
                  width='50'
                  style={{ top: '15px' }}
                />
              </div>
              <h2
                className={`mt-5 mb-4 pt-5 text-uppercase ${styles.boxTitle}`}
              >
                {intl.formatMessage({ id: 'OperatorAssistanceH1' })}
              </h2>
              <h3 className='mb-4'>
                {intl.formatMessage({ id: 'OperatorAssistanceP1' })}
              </h3>

              <div className='pb-3 mb-4 border-bottom border-secondary'>
                <h2 className='text-uppercase fw-bold pb-4'>
                  {intl.formatMessage({ id: 'OperatorAssistanceH2' })}
                </h2>

                <div className='d-flex gap-2 justify-content-between'>
                  <a href='tel:8183615437'>
                    <button className={`fs-5 ${styles.boxOperatorBtn}`}>
                      <StaticImage
                        src='../images/booknow/agent.png'
                        alt='agent'
                      />
                      <div>AGENT 1</div>
                    </button>
                  </a>
                  <a href='tel:6267958811'>
                    <button className={`fs-5 ${styles.boxOperatorBtn}`}>
                      <StaticImage
                        src='../images/booknow/agent.png'
                        alt='agent'
                      />
                      <div>AGENT 2</div>
                    </button>
                  </a>
                  <a href='tel:8188830460'>
                    <button className={`fs-5 ${styles.boxOperatorBtn}`}>
                      <StaticImage
                        src='../images/booknow/agent.png'
                        alt='agent'
                      />
                      <div>AGENT 3</div>
                    </button>
                  </a>
                </div>
                <p className='pt-2'>
                  {intl.formatMessage({ id: 'OperatorAssistanceP2' })}
                </p>
              </div>

              <div className='pb-4 mb-5'>
                <h2 className='text-uppercase fw-bold pb-4'>
                  {intl.formatMessage({ id: 'OperatorAssistanceH3' })}
                </h2>

                <div className='d-flex gap-2 justify-content-between'>
                  <a href='sms:8186582701'>
                    <button className={`fs-5 mb-0 ${styles.boxOperatorBtn}`}>
                      <div className='fs-1'>EN</div>
                      <div className='fs-5'>ENGLISH</div>
                    </button>
                  </a>
                  <a href='sms:8184235637'>
                    <button className={`fs-5 mb-0 ${styles.boxOperatorBtn}`}>
                      <div className='fs-1'>ES</div>
                      <div className='fs-5'>SPANISH</div>
                    </button>
                  </a>
                  <a href='https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F141266270391%2F%3Fmessaging_source%3Dsource%253Apages%253Amessage_shortlink'>
                    <button className={`fs-5 ${styles.boxOperatorBtn}`}>
                      <StaticImage
                        src='../images/booknow/messenger-icon.png'
                        alt='messenger'
                      />
                      <div className='fs-5 mt-2'>MESSENGER</div>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={8} className={` ${styles.request_appointment} px-5`}>
            <h3 className='mb-5'>
              {intl.formatMessage({ id: 'BookYorAppointment18' })}
            </h3>
            <p>
              {intl.formatMessage({ id: 'BookYorAppointment19' })}
              <br />
              <br />
              <span>{intl.formatMessage({ id: 'BookYorAppointment20' })}</span>
            </p>
          </Col>
          <Col lg={4}>
            <StaticImage src='../images/stayhome.png' alt='' />
          </Col>
        </Row>

        <hr />
        <h2
          className={`my-5 py-5 text-center  px-5 ${styles.appointment_counter}`}
        >
          Over <span>10,000</span>
          {intl.formatMessage({ id: 'BookYorAppointment21' })}
        </h2>
      </Container>

      <div className={`py-5 ${styles.contact_doctors}`}>
        <Container className='py-5'>
          <h1 className=' text-center py-5'>
            {intl.formatMessage({ id: 'BookYorAppointment22' })}
          </h1>
          <Row className='gx-5'>
            <Col lg={4}>
              <h3>
                <span>01</span>
                {intl.formatMessage({ id: 'BookYorAppointment23' })}
              </h3>
              <p>
                <a href='https://patientportal.intelichart.com/login/Account/Login?ReturnUrl=%2f'>
                  {intl.formatMessage({ id: 'BookYorAppointment24' })}
                </a>
                {intl.formatMessage({ id: 'BookYorAppointment25' })}
              </p>
            </Col>
            <Col lg={4}>
              <h3>
                <span>02</span> Text Us (24/7)
              </h3>
              <p>
                Send us a text on
                <a href='sms:8186582701'> 626-298-7121</a>
                <span> English</span>,<br />
                <a href='sms:8184235637'> 626-269-7744</a>
                <span> Spanish </span>
                to arrange an <br />
                {intl.formatMessage({ id: 'BookYorAppointment26' })}, 24/7.
              </p>
            </Col>
            <Col lg={4}>
              <h3>
                <span>03</span> Call Us
              </h3>
              <p>
                {intl.formatMessage({ id: 'BookYorAppointment27' })}
                <a href='tel:6267958811'> 626-795-8811 </a>
                {intl.formatMessage({ id: 'BookYorAppointment28' })}
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className={` ${styles.form} my-5 p-5 `} id='form'>
        <h1 className='mb-4'>
          {intl.formatMessage({ id: 'BookYorAppointment18' })}
        </h1>
        <p>{intl.formatMessage({ id: 'BookYorAppointment29' })}</p>
        <p>
          {intl.formatMessage({ id: 'BookYorAppointment30' })}
          <a href='tel:8183615437'>(818) 361-5437</a>.
          {intl.formatMessage({ id: 'BookYorAppointment31' })}
        </p>

        <Patientform />
      </Container>
    </Layout>
  )
}

export default RequestAppointment
